import React from 'react'
import {Link} from 'gatsby'

import style from './style.module.css';
import Pages from './pages/page'
import Treatment from './pages/treatment'
import Resources from './pages/resource'

const S = () =>{
	return(
		<div className={style.sitemapWrapper}>
			<div className={style.sitemapTitle}>
				<h2>Site Map</h2>
			</div>
			<div className={style.sitemapItemWrapper}>
				<Pages style={style}/>
				<Treatment style={style}/>
				<Resources style={style}/>
			</div>
		</div>
	)
}

export default S;