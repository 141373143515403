import React from 'react'
import Item from '../item'

const P = ({style}) => {
	const p = {
		title: 'Pages',
		items: [
		{
			name: 'Home',
			link: '/'
		},
		{
			name: 'About',
			link: '/about'
		},
		{
			name: 'Treatment',
			link: '/treatment-services'
		},
		{
			name: 'Resources',
			link: '/resources'
		},
		{
			name: 'Contact',
			link: '/contact'
		}]
	}
	return (
		<>
			<Item style={style} item={p}/>
		</>
	)
}

export default P