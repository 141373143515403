import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout'
import SiteMap from '../components/sitemap/index'

const SitemapPage = () => {
	return(
		<Layout>
			<SEO title="Site map " />
			<SiteMap/>
		</Layout>
	)
}

export default SitemapPage
