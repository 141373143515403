import React from 'react'
import {graphql, useStaticQuery} from "gatsby"
import Item from '../item'


const T = ({style}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiResources {
			    edges {
			      node {
			        name
			        slug
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiResources.edges.forEach(({node})=>{
		node.link = `/resources/${node.slug}`
		d.push(node);
	})

	let T = {
		title: 'Resources',
		items: d
	}
	return(
		<>
			<Item style={style} item={T}/>
		</>
	)
}

export default T