import React from 'react'
import {Link} from 'gatsby'

const I = ({style, item}) => {
	return(
		<div className={style.items}>
			<div className={style.itemTitle}>
				<h3>{item.title}</h3>
			</div>
			<div className={style.itemListing}>
				<ul>
					{
						item.items.map((i, index)=>{
							return(
								<li key={index}><Link to={i.link}>{i.name}</Link></li>
							)
						})
					}
				</ul>
			</div>
		</div>
	)
}

export default I